<template>
  <div class="payments-wrapper">
    <div class="payments">
      <h1>Upgrade to an Academy <br>
        Membership for €50/mo.</h1>
      <h2 class="black">All the tools, mentoring and resources you need to achieve <br>
        your research goals faster and more effectively.</h2>
      <ButtonNew mode="green" size="l" text="Buy now" class="button" :class="[nowPlan === 'member'? 'now': '']" :loading="loadingMember" @click="setMemberPlan"/>
      <p class="text after">Works like a gym membership. Pay monthly, cancel anytime. </p>
      <div class="subtitle">Here’s everything you get:</div>
      <div class="after">
        <div class="block">
          <div class="first-row">
            <div class="item">
              <p class="title">Tools</p>
              <p class="text">Full access to the PhD App — Research Canvas, Research Roadmap, and Research Sprints</p>
            </div>
            <div class="item">
              <p class="title">Content</p>
              <p class="text">Step-by-step online course on research skills and best practices on deconstructing your PhD</p>
            </div>
            <div class="item">
              <p class="title">Coaching</p>
              <p class="text">Monthly coaching calls with PhDs who have done it</p>
            </div>
          </div>
          <div class="second-row">
            <div class="item">
              <p class="title">Webinars</p>
              <p class="text">Ongoing training and monthly masterclasses</p>
            </div>
            <div class="item">
              <p class="title">Community</p>
              <p class="text">Connect with other like minded researchers</p>
            </div>
          </div>
        </div>
      </div>
      <h2 class="black">Don’t need step-by-step resources or coaching?</h2>
      <div class="subtitle">We also offer the tools with Pro and Basic plans.</div>
      <div class="block second-row">
        <div class="item">
          <p class="title">Pro</p>
          <p class="price">€25 per month</p>
          <p class="text">All the above, minus the coaching</p>
          <ButtonNew mode="green" size="l" text="Buy now" class="button" :class="[nowPlan === 'pro'? 'now': '']" :loading="loadingPro" @click="setProPlan"/>
        </div>
        <div class="item">
          <p class="title">Basic</p>
          <p class="price">€10 per month</p>
          <p class="text">Full access to the PhD App only </p>
          <ButtonNew mode="green" size="l" text="Buy now" class="button" :class="[nowPlan === 'basic'? 'now': '']" :loading="loadingBasic" @click="setBasicPlan"/>
        </div>
      </div>
    </div>
    <div class="partner">
      <img :src="require('@/assets/images/course/footer-logo.png')" alt="logo">
    </div>
  </div>

</template>

<script>
import ButtonNew from "@/components/UI-Kit/ButtonNew";
import {inject, ref, computed} from "vue"
import {getSubscriptionInfo, setNewPlan} from "@/api/pay";

export default {
  name: "Billing",
  components: {ButtonNew},
  props: {
    workspaceId: String
  },
  setup () {
    const subscription = inject('subscription')
    console.log(subscription.value)
    const loadingMember = ref(false)
    const loadingPro = ref(false)
    const loadingBasic = ref(false)

    const setMemberPlan = async function () {
      loadingMember.value = true
      await setNewPlan('member')
      subscription.value = await getSubscriptionInfo()
      console.log(subscription)
      loadingMember.value = false
    }

    const setProPlan = async function () {
      loadingPro.value = true
      await setNewPlan('pro')
      subscription.value = await getSubscriptionInfo()
      loadingPro.value = false
    }

    const setBasicPlan = async function () {
      loadingBasic.value = true
      await setNewPlan('basic')
      subscription.value = await getSubscriptionInfo()
      loadingBasic.value = false
    }

    const nowPlan = computed(() => {
      const amount = subscription.value.plan.amount
      if (amount === 1000)
        return 'basic'
      else if (amount === 2500)
        return 'pro'
      else if (amount === 5000)
        return 'member'
    })

    return {
      setMemberPlan, setProPlan, setBasicPlan,
      loadingBasic, loadingMember, loadingPro,
      nowPlan
    }
  }
}
</script>

<style scoped lang="sass">
@import "~@/assets/styles/color-scheme.scss"

.payments-wrapper
  background-color: #E5E5E5
  width: 100%
  min-height: 100vh
  padding: 15px 15%
*
  box-sizing: border-box
.payments
  background: #E5E5E5
  min-height: 100vh
  padding: 60px 0 135px
h1
  font-size: 48px
  line-height: 56px
  margin-bottom: 32px
h2
  font-size: 32px
  line-height: 38px
  margin-bottom: 32px
.pxp-button
  margin-bottom: 32px
p
  color: #000000
  line-height: 16px
.after
  padding-bottom: 32px
  margin-bottom: 32px
  position: relative
  &::after
    content: ''
    width: 48px
    height: 1px
    background-color: #BFC4D6
    position: absolute
    bottom: 0
    left: 50%
    margin-left: -24px
.subtitle
  font-weight: bold
  font-size: 22px
  line-height: 26px
  color: #000000
  margin-bottom: 32px
.block
  background-color: #fff
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05)
  border-radius: 8px
  width: 900px
  margin: 0 auto
.first-row
  display: grid
  grid-template-columns: repeat(3, 1fr)
  border-bottom: 1px solid #E3E5EC
.second-row
  display: grid
  grid-template-columns: repeat(2, 1fr)
  .pxp-button
    margin-bottom: 0
.item
  padding: 32px 25px
  border-right: 1px solid #E3E5EC
  .title
    font-weight: 500
    font-size: 18px
    line-height: 21px
    color: #13246E
    margin-bottom: 16px
  .price
    font-weight: bold
    margin-bottom: 16px
  .text
    margin-bottom: 32px

.button
  &.now
    border: 3px solid lightgray
</style>
